<template>
  <fw-layout wide back-to="/manage" management mobile-ready>
    <template #main-content>
      <fw-panel title="Cursos" subtitle="Certificados de formação" featured>
        <template #toolbar>
          <fw-button type="link" @click.native="openAddModal">Criar novo curso</fw-button>
        </template>
        <PanelManageCourses ref="coursesList" @edit="openModal" @delete="comfirmDelete" />
      </fw-panel>
    </template>
    <template #modals>
      <fw-modal :active.sync="isModalActive" :can-cancel="true" size="auto" boxed="sm" @close="closeModal">
        <ModalManageCourseMetadata
          :saving-data="loading"
          :can-edit="true"
          :course="selectedCourse"
          @save="saveCourse"
          @close="closeModal"
        />
      </fw-modal>
    </template>
  </fw-layout>
</template>

<script>
import PanelManageCourses from '@/components/panels/manage/PanelManageCourses'
import ModalManageCourseMetadata from '@/components/modals/ModalManageCourseMetadata'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    PanelManageCourses,
    ModalManageCourseMetadata
  },

  data() {
    return {
      isModalActive: false,
      loading: false,
      selectedCourse: null
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    permissions() {
      return this.$store.getters.userPermissions
    }
  },

  methods: {
    closeModal() {
      this.selectedCourse = null
      this.isModalActive = false
    },

    openAddModal() {
      this.openModal({
        title: null
      })
    },

    openModal(item) {
      this.selectedCourse = item
      this.isModalActive = true
    },

    saveCourse(data) {
      if (data.key) this.updateCourse(data)
      else this.createCourse(data)
      this.closeModal()
    },

    async createCourse(data) {
      this.loading = true

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.addManagerCourses(data)
        console.log('addManagerCourses :>> ', response)
        this.$router.push({ name: 'manage-course-editions', params: { key: response.course.key } })
      })
      this.loading = false
    },

    async updateCourse(data) {
      this.loading = true

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.editManagerCourse(data.key, data)
        console.log('editManagerCourse :>> ', response)
        this.$refs.coursesList.getCourses()
      })

      this.loading = false
    },

    comfirmDelete(key) {
      this.$buefy.dialog.confirm({
        confirmText: 'Eliminar',
        type: 'is-danger',
        cancelText: 'Cancelar',
        title: 'Eliminar curso',
        message: `<div class="has-margin-bottom-small">Tem a certeza que deseja <strong>eliminar</strong> este curso?
        Todos os dados serão eliminados e não será possível recuperar qualquer informação.</div>`,
        onConfirm: () => {
          this.deleteCourse(key)
        }
      })
    },

    async deleteCourse(key) {
      this.loading = true

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.deleteManagerCourse(key)
        console.log('deleteManagerCourse :>> ', response)
        this.$router.push({ name: 'manage-courses' })
      })

      this.loading = false
    }
  }
}
</script>
